<template>
	<v-flex ma-1 shrink>
		<v-layout v-ripple="!readonly" align-center :class="{'pointer': !readonly, 'w-color-picker__container': true, 'w-color-picker__labelled-container': !!label}" row :style="`border-radius:${size};height:${size}`"  @click="openColorPicker()">
			<v-flex class="w-color-picker__activator" :style="`background-color:${value};height:${size};width:${size}`" />
			<input ref="color-picker" class="w-color-picker__input" type="color" :value="value" @input="onColorSelected($event)" />
			<v-flex v-if="label" class="w-color-picker__label" ml-2 mr-3 shrink v-text="label" />
		</v-layout>
	</v-flex>
</template>

<script>
/**
 * @displayName components/Commons/Pickers/ColorPicker
 */
export default {
	name: 'ColorPicker',
	props: {
		label: {
			default: undefined,
			required: false,
			type: String
		},
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		size: {
			default: '42px',
			required: false,
			type: String
		},
		/**
		 * @model
		 */
		value: {
			default: '#000000',
			type: String,
			required: false
		}
	},
	methods: {
		onColorSelected: function (event) {
			this.$emit('input', event.target.value)
		},
		openColorPicker: function () {
			if (this.readonly) {
				return
			}
			this.$refs['color-picker'].click()
		}
	}
}
</script>

<style>
.theme--dark .w-color-picker__labelled-container {
	background-color: #424242;
	border-color: white;
}

.theme--light .w-color-picker__labelled-container {
	background-color: #bdbdbd;
	border-color: black;
}

.w-color-picker__container {
	position: relative;
}

.w-color-picker__labelled-container {
	border-style: solid;
	border-width: 1px;
	border-left: none;
}

.w-color-picker__activator {
	border-radius: 50%;
}

.w-color-picker__input {
	position: absolute;
	visibility: hidden;
	width: 0;
}

.w-color-picker__label {
	line-height: 0;
}
</style>
